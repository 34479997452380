.App {
  background-image: url("../imagens/background.webp");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-image: url("../imagens/background.webp");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
  height: 1000px;
  min-height: 600px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-direction: line;
  align-content: center;
  align-items: center; 
  color:  rgb(24, 238, 5);
  text-decoration-color: #000;
  font-size: calc(12px + 3vmin);

}
.Menu {
  background-color:  rgb(24, 238, 5);
	height:auto;
  display: flex;
  flex-direction: line;
  text-align: center;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Inicio {
  background-color: #FF0037;
  color: #fff;
	max-width:100%;
  min-height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 300px;
  font-size: calc(10px + 3vmin);
  padding: 50px;
}

.Sistemas {
  background-color: white;
	width: 100%;
	height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.SistemaInterno {
  background-color: #fff;
  color: #000;
  display: inline-block;
  align-content: space-between;
  margin: 10px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  height: auto;
  width: 48%;
  max-width: 48%;
  min-height: auto;
  font-size: calc(8px + 2vmin);
}
.Planos {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: space-between;
  text-align: center;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.PlanoInterno {
  background-color: #FF0037;
  height: 200px;
  width: 300px;
  max-width: 300px;
  max-width: 300px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 1.5em;
	height:auto;
  display: inline-block;
  flex-direction: column;
  color: #fff;
  text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
}

.Cidades {
  background-color: rgb(24, 238, 5);
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Cidadescada {
  background-color: #fff;
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  font-size: calc(8px + 2vmin);
}
.Peca {
  background-color: #FF0037;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}

.Rodape {
  background-color: #424242;
  color: #fff;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(5px + 1vmin);
  align-items: center;
}
.Reservado {
  background-color: #424242;
  color: #fff;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(5px + 1vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-logo {
	max-width:30%;
  min-width: 10%;
  height: auto;
  width: 200px;
  margin: 10px;
  align-content: center;
}

.Img {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.Textocircuito-divesquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}
.App-divdireitat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 3vmin);
  color: black;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #000;
	}
a:hover {
	text-decoration:none; 
	color:#696867; 
	}

.action{
    padding: 0px;
    margin: 10px;
    align-items: center;
    display: inline-block;
    border-radius: 4px;;
  }

  .form input, textarea, select{
    margin-bottom: 1em;
    padding: .7em;
    border:0;
    border-radius: 5px;
    max-width: 600px;
  }

